<template>
    <v-container class="p-relative">
        <v-row>
            <v-col cols="12" md="12" class="py-0">
                <RegistryHeader
                    :title="getServicePricesRegistry.name"
                    :avatarData="getServicePricesRegistry.currentMenager"
                    @clearSearch="clearTable"
                />
                <Table
                    :title="getServicePricesRegistry.name"
                    :items="getServicePricesTable.items"
                    :headers="headers"
                    :length="getServicePricesTable.pages"
                    :page="getServicePricesTable.page"
                    @search="setSearch"
                    @clear-table="clearTable"
                    @search-fields="setSearchFields"
                    @change-page="setPage"
                    @sort="setSort"
                    :defaultFields="getServicePricesSearchFields.searchFields"
                    :loading="getServicePricesTable.loading"
                >
                    <Columns
                        slot="columns"
                        :items="getServicePricesTable.items"
                    />
                </Table>
                <Modal
                    :title="$t('service-prices:additionPrice')"
                    :open="open"
                    :width="'1100px'"
                    :height="'670'"
                    v-on:close="closeModal"
                >
                    <AEContent slot="AEContent" />
                    <Buttons
                        :action="'create'"
                        slot="buttons"
                        @closeModal="open = false"
                    />
                </Modal>
                <div class="d-flex justify-end buttons--action">
                    <v-tooltip left>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                data-cy="addEntry"
                                fab
                                dark
                                v-on="on"
                                small
                                v-if="add()"
                                @click="addPrice()"
                                class="buttons--add margin--1"
                            >
                                <v-icon small>mdi-plus</v-icon>
                            </v-btn>
                        </template>
                        <span>{{ $t('service-prices:addPrice') }}</span>
                    </v-tooltip>
                    <span></span>
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import store from './../../../store/index'
import Columns from './../../../components/Registries/Service-prices/Table/Columns'
import AEContent from './../../../components/Registries/Service-prices/Modal/Content'
import Buttons from './../../../components/Registries/Service-prices/Modal/Buttons'
export default {
    data() {
        return {
            open: false,
            headers: [
                {
                    text: this.$t('service-prices:name'),
                    value: 'name',
                    width: '32%',
                    showSearch: true,
                },
                {
                    text: this.$t('service-prices:description'),
                    value: 'description',
                    width: '62%',
                    showSearch: true,
                },
                {
                    text: '',
                    value: 'arrow',
                    align: 'right',
                    width: '6%',
                    sortable: false,
                },
            ],
        }
    },
    components: {
        Columns,
        AEContent,
        Buttons,
    },
    computed: {
        ...mapGetters([
            'getServicePricesTable',
            'getServicePricesSearchFields',
            'getServicePricesRegistry',
            'getSearch',
        ]),
    },
    methods: {
        ...mapActions(['fetchServicePricesTable']),
        ...mapMutations([
            'clearServicePricesModal',
            'clearServicePricesErrors',
            'setServicePricesTable',
            'setServicePricesSearch',
            'clearServicePricesTable',
        ]),
        closeModal() {
            this.open = false
        },
        add() {
            return this.checkPermissions('CREATE')
        },
        async addPrice() {
            this.open = true
            this.clearServicePricesErrors()
            this.clearServicePricesModal()
        },
        setSearchFields(searchFields) {
            this.setServicePricesSearch(searchFields)
        },
        setSearch(search) {
            this.setServicePricesTable({ search, page: 1 })
            this.fetchServicePricesTable()
        },
        setPage(page) {
            this.setServicePricesTable({ page })
            this.fetchServicePricesTable()
        },
        setSort(sort) {
            this.setServicePricesTable({ sort })
            this.fetchServicePricesTable()
        },
        clearTable() {
            this.getSearch.text = ''
            this.clearServicePricesTable()
            this.fetchServicePricesTable()
        },
    },
    beforeRouteEnter(to, from, next) {
        if (from.matched[1] && from.matched[1].path !== '/service-prices/:id')
            store.commit('clearServicePricesTable')
        store.dispatch('fetchServicePricesTable').then(() => next())
    },
}
</script>
