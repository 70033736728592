var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"pt-0"},[_c('v-row',[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[_c('v-stepper',{staticClass:"elevation-0",model:{value:(_vm.getServicePricesStep.step),callback:function ($$v) {_vm.$set(_vm.getServicePricesStep, "step", $$v)},expression:"getServicePricesStep.step"}},[[_c('v-stepper-header',{staticClass:"elevation-0 stepperHeader"},[_c('v-divider'),_c('v-stepper-step',{attrs:{"rules":[
                                () =>
                                    _vm.getServicePricesErrors.stepOne
                                        .validation,
                            ],"editable":"","step":"1"}},[_vm._v(_vm._s(_vm.$t('service-prices:informacion')))]),_c('v-divider')],1),_c('v-stepper-items',[_c('v-stepper-content',{attrs:{"step":"1"}},[_c('v-row',[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12","md":"12"}},[_c('Separator',{attrs:{"text":_vm.$t('service-prices:informacion')}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":`${_vm.$t(
                                            'service-prices:name'
                                        )}*`,"error-messages":_vm.getServicePricesErrors.stepOne
                                                .fields.name},model:{value:(_vm.getServicePricesModal.name),callback:function ($$v) {_vm.$set(_vm.getServicePricesModal, "name", $$v)},expression:"getServicePricesModal.name"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"label":`${_vm.$t(
                                            'service-prices:description'
                                        )}*`,"error-messages":_vm.getServicePricesErrors.stepOne
                                                .fields.description,"rows":"1","auto-grow":""},model:{value:(
                                            _vm.getServicePricesModal.description
                                        ),callback:function ($$v) {_vm.$set(_vm.getServicePricesModal, "description", $$v)},expression:"\n                                            getServicePricesModal.description\n                                        "}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('v-row',[_c('v-col',[_c('money-field',{attrs:{"label":`${_vm.$t(
                                                    'service-prices:survey.default'
                                                )}`},model:{value:(
                                                    _vm.getServicePricesModal
                                                        .survey.price
                                                ),callback:function ($$v) {_vm.$set(_vm.getServicePricesModal
                                                        .survey, "price", $$v)},expression:"\n                                                    getServicePricesModal\n                                                        .survey.price\n                                                "}})],1),_c('v-col',[_c('v-select',{attrs:{"items":_vm.unit,"item-text":"name","item-value":"value","label":`${_vm.$t(
                                                    'service-prices:unit'
                                                )}`},model:{value:(
                                                    _vm.getServicePricesModal
                                                        .survey.value
                                                ),callback:function ($$v) {_vm.$set(_vm.getServicePricesModal
                                                        .survey, "value", $$v)},expression:"\n                                                    getServicePricesModal\n                                                        .survey.value\n                                                "}})],1)],1)],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-row',[_c('v-col',[_c('money-field',{attrs:{"label":`${_vm.$t(
                                                    'service-prices:repair.default'
                                                )}`},model:{value:(
                                                    _vm.getServicePricesModal
                                                        .repair.price
                                                ),callback:function ($$v) {_vm.$set(_vm.getServicePricesModal
                                                        .repair, "price", $$v)},expression:"\n                                                    getServicePricesModal\n                                                        .repair.price\n                                                "}})],1),_c('v-col',[_c('v-select',{attrs:{"items":_vm.unit,"item-text":"name","item-value":"value","label":`${_vm.$t(
                                                    'service-prices:unit'
                                                )}`},model:{value:(
                                                    _vm.getServicePricesModal
                                                        .repair.value
                                                ),callback:function ($$v) {_vm.$set(_vm.getServicePricesModal
                                                        .repair, "value", $$v)},expression:"\n                                                    getServicePricesModal\n                                                        .repair.value\n                                                "}})],1)],1)],1),_c('v-col',{attrs:{"offset":"2","cols":"2"}},[_c('v-col',[_c('money-field',{attrs:{"label":`${_vm.$t(
                                                'service-prices:rateOneKmTransport'
                                            )}`},model:{value:(
                                                _vm.getServicePricesModal
                                                    .rateOneKmTransport
                                                    .price
                                            ),callback:function ($$v) {_vm.$set(_vm.getServicePricesModal
                                                    .rateOneKmTransport
                                                    , "price", $$v)},expression:"\n                                                getServicePricesModal\n                                                    .rateOneKmTransport\n                                                    .price\n                                            "}})],1)],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-col',[_c('money-field',{attrs:{"label":`${_vm.$t(
                                                'service-prices:staticRateTransport'
                                            )}`},model:{value:(
                                                _vm.getServicePricesModal
                                                    .staticRateTransport
                                                    .price
                                            ),callback:function ($$v) {_vm.$set(_vm.getServicePricesModal
                                                    .staticRateTransport
                                                    , "price", $$v)},expression:"\n                                                getServicePricesModal\n                                                    .staticRateTransport\n                                                    .price\n                                            "}})],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('v-row',[_c('v-col',[_c('money-field',{attrs:{"label":`${_vm.$t(
                                                    'service-prices:installation.default'
                                                )}`},model:{value:(
                                                    _vm.getServicePricesModal
                                                        .installation.price
                                                ),callback:function ($$v) {_vm.$set(_vm.getServicePricesModal
                                                        .installation, "price", $$v)},expression:"\n                                                    getServicePricesModal\n                                                        .installation.price\n                                                "}})],1),_c('v-col',[_c('v-select',{attrs:{"items":_vm.unit,"item-text":"name","item-value":"value","label":`${_vm.$t(
                                                    'service-prices:unit'
                                                )}`},model:{value:(
                                                    _vm.getServicePricesModal
                                                        .installation.value
                                                ),callback:function ($$v) {_vm.$set(_vm.getServicePricesModal
                                                        .installation, "value", $$v)},expression:"\n                                                    getServicePricesModal\n                                                        .installation.value\n                                                "}})],1)],1)],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-row',[_c('v-col',[_c('money-field',{attrs:{"label":`${_vm.$t(
                                                    'service-prices:diagnostics.default'
                                                )}`},model:{value:(
                                                    _vm.getServicePricesModal
                                                        .diagnostics.price
                                                ),callback:function ($$v) {_vm.$set(_vm.getServicePricesModal
                                                        .diagnostics, "price", $$v)},expression:"\n                                                    getServicePricesModal\n                                                        .diagnostics.price\n                                                "}})],1),_c('v-col',[_c('v-select',{attrs:{"items":_vm.unit,"item-text":"name","item-value":"value","label":`${_vm.$t(
                                                    'service-prices:unit'
                                                )}`},model:{value:(
                                                    _vm.getServicePricesModal
                                                        .diagnostics.value
                                                ),callback:function ($$v) {_vm.$set(_vm.getServicePricesModal
                                                        .diagnostics, "value", $$v)},expression:"\n                                                    getServicePricesModal\n                                                        .diagnostics.value\n                                                "}})],1)],1)],1),_c('v-col',{attrs:{"offset":"2","cols":"4"}},[_c('v-row',[_c('v-col',[_c('v-checkbox',{attrs:{"label":`${_vm.$t(
                                                    'service-prices:minRateTransport'
                                                )}`},model:{value:(
                                                    _vm.getServicePricesModal
                                                        .minRateTransport
                                                        .value
                                                ),callback:function ($$v) {_vm.$set(_vm.getServicePricesModal
                                                        .minRateTransport
                                                        , "value", $$v)},expression:"\n                                                    getServicePricesModal\n                                                        .minRateTransport\n                                                        .value\n                                                "}})],1),_c('v-col',[_c('v-col',[(
                                                        _vm.getServicePricesModal
                                                            .minRateTransport
                                                            .value
                                                    )?_c('money-field',{attrs:{"label":`${_vm.$t(
                                                        'service-prices:minRateTransport'
                                                    )}`},model:{value:(
                                                        _vm.getServicePricesModal
                                                            .minRateTransport
                                                            .price
                                                    ),callback:function ($$v) {_vm.$set(_vm.getServicePricesModal
                                                            .minRateTransport
                                                            , "price", $$v)},expression:"\n                                                        getServicePricesModal\n                                                            .minRateTransport\n                                                            .price\n                                                    "}}):_vm._e()],1)],1)],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-row',[_c('v-col',[_c('money-field',{attrs:{"label":`${_vm.$t(
                                                    'service-prices:itService.default'
                                                )}`},model:{value:(
                                                    _vm.getServicePricesModal
                                                        .itService.price
                                                ),callback:function ($$v) {_vm.$set(_vm.getServicePricesModal
                                                        .itService, "price", $$v)},expression:"\n                                                    getServicePricesModal\n                                                        .itService.price\n                                                "}})],1),_c('v-col',[_c('v-select',{attrs:{"items":_vm.unit,"item-text":"name","item-value":"value","label":`${_vm.$t(
                                                    'service-prices:unit'
                                                )}`},model:{value:(
                                                    _vm.getServicePricesModal
                                                        .itService.value
                                                ),callback:function ($$v) {_vm.$set(_vm.getServicePricesModal
                                                        .itService, "value", $$v)},expression:"\n                                                    getServicePricesModal\n                                                        .itService.value\n                                                "}})],1),_c('v-col',[_c('money-field',{attrs:{"label":`${_vm.$t(
                                                    'service-prices:remote.default'
                                                )}`},model:{value:(
                                                    _vm.getServicePricesModal
                                                        .remote.price
                                                ),callback:function ($$v) {_vm.$set(_vm.getServicePricesModal
                                                        .remote, "price", $$v)},expression:"\n                                                    getServicePricesModal\n                                                        .remote.price\n                                                "}})],1),_c('v-col',[_c('v-select',{attrs:{"items":_vm.unit,"item-text":"name","item-value":"value","label":`${_vm.$t(
                                                    'service-prices:unit'
                                                )}*`},model:{value:(
                                                    _vm.getServicePricesModal
                                                        .remote.value
                                                ),callback:function ($$v) {_vm.$set(_vm.getServicePricesModal
                                                        .remote, "value", $$v)},expression:"\n                                                    getServicePricesModal\n                                                        .remote.value\n                                                "}})],1)],1)],1),_c('v-col',{attrs:{"offset":"2"}},[_c('v-row',[_c('v-col',[_c('money-field',{attrs:{"label":`${_vm.$t(
                                                    'service-prices:ownManHoursCost'
                                                )}`},model:{value:(
                                                    _vm.getServicePricesModal
                                                        .ownManHoursCost
                                                        .price
                                                ),callback:function ($$v) {_vm.$set(_vm.getServicePricesModal
                                                        .ownManHoursCost
                                                        , "price", $$v)},expression:"\n                                                    getServicePricesModal\n                                                        .ownManHoursCost\n                                                        .price\n                                                "}})],1),_c('v-col',[_c('v-text-field',{attrs:{"rules":_vm.precentagesRules,"type":"number","label":`${_vm.$t(
                                                    'service-prices:partsDiscountInPercent'
                                                )}`},model:{value:(
                                                    _vm.getServicePricesModal
                                                        .partsDiscountInPercent
                                                        .price
                                                ),callback:function ($$v) {_vm.$set(_vm.getServicePricesModal
                                                        .partsDiscountInPercent
                                                        , "price", $$v)},expression:"\n                                                    getServicePricesModal\n                                                        .partsDiscountInPercent\n                                                        .price\n                                                "}})],1)],1)],1)],1)],1)],1)]],2)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }