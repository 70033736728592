<template>
    <v-container class="pt-0">
        <v-row>
            <v-col cols="12" class="pa-0">
                <v-stepper
                    v-model="getServicePricesStep.step"
                    class="elevation-0"
                >
                    <template>
                        <v-stepper-header class="elevation-0 stepperHeader">
                            <v-divider></v-divider>
                            <v-stepper-step
                                :rules="[
                                    () =>
                                        getServicePricesErrors.stepOne
                                            .validation,
                                ]"
                                editable
                                step="1"
                                >{{
                                    $t('service-prices:informacion')
                                }}</v-stepper-step
                            >
                            <v-divider></v-divider>
                        </v-stepper-header>
                        <v-stepper-items>
                            <v-stepper-content step="1">
                                <v-row>
                                    <v-col cols="12" md="12" class="pt-0">
                                        <Separator
                                            :text="
                                                $t('service-prices:informacion')
                                            "
                                        />
                                    </v-col>
                                </v-row>
                                <v-row
                                    ><v-col cols="12"
                                        ><v-text-field
                                            v-model="getServicePricesModal.name"
                                            :label="`${$t(
                                                'service-prices:name'
                                            )}*`"
                                            :error-messages="
                                                getServicePricesErrors.stepOne
                                                    .fields.name
                                            "
                                        ></v-text-field> </v-col
                                ></v-row>
                                <v-row
                                    ><v-col cols="12"
                                        ><v-textarea
                                            v-model="
                                                getServicePricesModal.description
                                            "
                                            :label="`${$t(
                                                'service-prices:description'
                                            )}*`"
                                            :error-messages="
                                                getServicePricesErrors.stepOne
                                                    .fields.description
                                            "
                                            rows="1"
                                            auto-grow
                                        ></v-textarea></v-col
                                ></v-row>
                                <v-row
                                    ><v-col cols="3">
                                        <v-row>
                                            <v-col>
                                                <money-field
                                                    :label="`${$t(
                                                        'service-prices:survey.default'
                                                    )}`"
                                                    v-model="
                                                        getServicePricesModal
                                                            .survey.price
                                                    "
                                                ></money-field>
                                            </v-col>
                                            <v-col>
                                                <v-select
                                                    v-model="
                                                        getServicePricesModal
                                                            .survey.value
                                                    "
                                                    :items="unit"
                                                    item-text="name"
                                                    item-value="value"
                                                    :label="`${$t(
                                                        'service-prices:unit'
                                                    )}`"
                                                >
                                                </v-select
                                            ></v-col>
                                        </v-row> </v-col
                                    ><v-col cols="3">
                                        <v-row>
                                            <v-col>
                                                <money-field
                                                    :label="`${$t(
                                                        'service-prices:repair.default'
                                                    )}`"
                                                    v-model="
                                                        getServicePricesModal
                                                            .repair.price
                                                    "
                                                ></money-field>
                                            </v-col>
                                            <v-col>
                                                <v-select
                                                    v-model="
                                                        getServicePricesModal
                                                            .repair.value
                                                    "
                                                    :items="unit"
                                                    item-text="name"
                                                    item-value="value"
                                                    :label="`${$t(
                                                        'service-prices:unit'
                                                    )}`"
                                                ></v-select
                                            ></v-col>
                                        </v-row>
                                    </v-col>
                                    <!-- STAWKA ZA 1 KM TRANSPORTU -->
                                    <v-col offset="2" cols="2">
                                        <v-col>
                                            <money-field
                                                :label="`${$t(
                                                    'service-prices:rateOneKmTransport'
                                                )}`"
                                                v-model="
                                                    getServicePricesModal
                                                        .rateOneKmTransport
                                                        .price
                                                "
                                            ></money-field>
                                        </v-col>
                                    </v-col>
                                    <v-col cols="2">
                                        <v-col>
                                            <money-field
                                                :label="`${$t(
                                                    'service-prices:staticRateTransport'
                                                )}`"
                                                v-model="
                                                    getServicePricesModal
                                                        .staticRateTransport
                                                        .price
                                                "
                                            ></money-field>
                                        </v-col>
                                    </v-col>
                                </v-row>
                                <v-row
                                    ><v-col cols="3">
                                        <v-row>
                                            <v-col>
                                                <money-field
                                                    :label="`${$t(
                                                        'service-prices:installation.default'
                                                    )}`"
                                                    v-model="
                                                        getServicePricesModal
                                                            .installation.price
                                                    "
                                                ></money-field
                                            ></v-col>
                                            <v-col>
                                                <v-select
                                                    v-model="
                                                        getServicePricesModal
                                                            .installation.value
                                                    "
                                                    :items="unit"
                                                    item-text="name"
                                                    item-value="value"
                                                    :label="`${$t(
                                                        'service-prices:unit'
                                                    )}`"
                                                ></v-select
                                            ></v-col>
                                        </v-row> </v-col
                                    ><v-col cols="3">
                                        <v-row>
                                            <v-col
                                                ><money-field
                                                    :label="`${$t(
                                                        'service-prices:diagnostics.default'
                                                    )}`"
                                                    v-model="
                                                        getServicePricesModal
                                                            .diagnostics.price
                                                    "
                                                ></money-field
                                            ></v-col>
                                            <v-col>
                                                <v-select
                                                    v-model="
                                                        getServicePricesModal
                                                            .diagnostics.value
                                                    "
                                                    :items="unit"
                                                    item-text="name"
                                                    item-value="value"
                                                    :label="`${$t(
                                                        'service-prices:unit'
                                                    )}`"
                                                ></v-select
                                            ></v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col offset="2" cols="4">
                                        <v-row>
                                            <!-- STAWKA MINIMALNA ZA TRANSPORT -->
                                            <v-col>
                                                <v-checkbox
                                                    :label="`${$t(
                                                        'service-prices:minRateTransport'
                                                    )}`"
                                                    v-model="
                                                        getServicePricesModal
                                                            .minRateTransport
                                                            .value
                                                    "
                                                ></v-checkbox>
                                            </v-col>
                                            <v-col>
                                                <v-col>
                                                    <money-field
                                                        v-if="
                                                            getServicePricesModal
                                                                .minRateTransport
                                                                .value
                                                        "
                                                        :label="`${$t(
                                                            'service-prices:minRateTransport'
                                                        )}`"
                                                        v-model="
                                                            getServicePricesModal
                                                                .minRateTransport
                                                                .price
                                                        "
                                                    ></money-field> </v-col
                                            ></v-col> </v-row
                                    ></v-col>
                                </v-row>
                                <v-row
                                    ><v-col cols="6">
                                        <v-row>
                                            <v-col
                                                ><money-field
                                                    :label="`${$t(
                                                        'service-prices:itService.default'
                                                    )}`"
                                                    v-model="
                                                        getServicePricesModal
                                                            .itService.price
                                                    "
                                                ></money-field
                                            ></v-col>
                                            <v-col>
                                                <v-select
                                                    v-model="
                                                        getServicePricesModal
                                                            .itService.value
                                                    "
                                                    :items="unit"
                                                    item-text="name"
                                                    item-value="value"
                                                    :label="`${$t(
                                                        'service-prices:unit'
                                                    )}`"
                                                ></v-select
                                            ></v-col>
                                            <v-col
                                                ><money-field
                                                    v-model="
                                                        getServicePricesModal
                                                            .remote.price
                                                    "
                                                    :label="`${$t(
                                                        'service-prices:remote.default'
                                                    )}`"
                                                ></money-field
                                            ></v-col>
                                            <v-col>
                                                <v-select
                                                    v-model="
                                                        getServicePricesModal
                                                            .remote.value
                                                    "
                                                    :items="unit"
                                                    item-text="name"
                                                    item-value="value"
                                                    :label="`${$t(
                                                        'service-prices:unit'
                                                    )}*`"
                                                ></v-select
                                            ></v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col offset="2">
                                        <v-row>
                                            <v-col>
                                                <money-field
                                                    :label="`${$t(
                                                        'service-prices:ownManHoursCost'
                                                    )}`"
                                                    v-model="
                                                        getServicePricesModal
                                                            .ownManHoursCost
                                                            .price
                                                    "
                                                ></money-field>
                                            </v-col>
                                            <v-col>
                                                <v-text-field
                                                    :rules="precentagesRules"
                                                    type="number"
                                                    :label="`${$t(
                                                        'service-prices:partsDiscountInPercent'
                                                    )}`"
                                                    v-model="
                                                        getServicePricesModal
                                                            .partsDiscountInPercent
                                                            .price
                                                    "
                                                ></v-text-field
                                            ></v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                            </v-stepper-content>
                        </v-stepper-items>
                    </template>
                </v-stepper>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
    data() {
        return {
            editable: true,
            unit: [
                { name: this.$t('service-prices:HOUR'), value: 'HOUR' },
                { name: this.$t('service-prices:ITEM'), value: 'ITEM' },
            ],
            precentagesRules: [
                v =>
                    (v && v >= 0 && v <= 100) ||
                    'Liczba musi zawierać się pomiędzy 0 a 100',
            ],
        }
    },

    methods: {},

    computed: {
        ...mapGetters([
            'getServicePricesStep',
            'getServicePricesErrors',
            'getServicePricesModal',
        ]),
    },
}
</script>
